import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, each, compact, transform, isEmpty, get, isNaN } from 'lodash';
import AddonListComponent from "./addon-list";
import { focusField, scrollTo } from '../../common/utilities';
import { ClickableErrorMessage } from '../../common/components/error/ClickableErrorMessage';
import EquipmentFileUpload from './EquipmentFileUpload';

class VarListItemComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isExpanded: this.props.isExpanded,
        };

        this.scrollTo = scrollTo(props.varItem);
        this.focusField = focusField(props.varItem);
    }

    varSelect = (e, unselectOther = true) => {
        let varItem;
        if ((this.props.merchantVar && this.props.merchantVar.isSelected)
            && e.target.value === this.props.varItem.equipmentId && !e.target.checked)
        {
            varItem = cloneDeep(this.props.merchantVar);
            varItem.isSelected = false;
        }
        else if ((!this.props.merchantVar || !this.props.merchantVar.isSelected)
            && e.target.value === this.props.varItem.equipmentId && e.target.checked)
        {
            varItem = cloneDeep(this.props.merchantVar) || this.props.createNewEquipment(this.props.varItem.equipmentId, true);
            varItem.isSelected = true;            
            this.toggleSection(true);
        }
        
        if (varItem) {
            this.props.varSelect(varItem, varItem.isSelected && unselectOther);
        }
    }

    subOptionSelect = (equipmentId) => (e) => {
        let varItem = cloneDeep(this.props.merchantVar);
        let eqp = varItem.subequipment.find(s => s.equipmentId == equipmentId);
        if (!eqp)
            return;

        if (!eqp.equipmentOptions) {
            eqp.equipmentOptions = {};
        }

        if (e.target.checked) {
            eqp.equipmentOptions[e.target.value] = '1'; // true?
        } else if (Object.keys(eqp.equipmentOptions).includes(e.target.value)) {
            delete eqp.equipmentOptions[e.target.value];
        }

        this.props.onChange(varItem);
    }
    subOptionChange = (equipmentId) => (e) => {
        let varItem = cloneDeep(this.props.merchantVar);
        let eqp = varItem.subequipment.find(s => s.equipmentId == equipmentId);
        if (!eqp)
            return;
        if (!eqp.equipmentOptions) {
            eqp.equipmentOptions = {};
        }
        let inputName = e.target.name;
        let optionName = inputName.substr(inputName.indexOf('_equipmentOptions__') + '_equipmentOptions__'.length);
        //console.log(optionName);
        eqp.equipmentOptions[optionName] = e.target.value; 
        
        this.props.onChange(varItem);
    }

    handleRemoveFile = (fileType, i) => {
        let varItem = cloneDeep(this.props.merchantVar);
        varItem.attachment.file = {};
        this.props.onChange(varItem);
    }

    onDropFile = (fileType, acceptedFiles) => {
        let varItem = cloneDeep(this.props.merchantVar);
        // will really be only a single file, but param type is an array
        varItem.attachment = {};
        each(acceptedFiles, f => varItem.attachment.file = f);
        console.log(varItem.attachment.file);
        this.props.onChange(varItem);
    }

    optionSelect = (e) => {
        let varItem = cloneDeep(this.props.merchantVar);
        if (!varItem.equipmentOptions) {
            varItem.equipmentOptions = {};
        }
        if (e.target.checked) {
            varItem.equipmentOptions[e.target.value] = '1'; // true?
        } else if (Object.keys(varItem.equipmentOptions).includes(e.target.value)) {
            delete varItem.equipmentOptions[e.target.value];
        }    
        this.props.onChange(varItem);
    }

    optionSetMoreInfo = (optKey, optValue) => (e) => {
        let varItem = cloneDeep(this.props.merchantVar);
        varItem.equipmentOptions[optKey] = `${optValue}|${e.target.value}`;
        this.props.onChange(varItem);
    }

    suboptionSetMoreInfo = (equipmentId, optKey, optValue) => (e) => {
        let varItem = cloneDeep(this.props.merchantVar);
        let eqp = varItem.subequipment.find(s => s.equipmentId == equipmentId);
        if (!eqp)
            return;

        eqp.equipmentOptions[optKey] = `${optValue}|${e.target.value}`;
        this.props.onChange(varItem);
    }

    selectPurchaseType = (e) => {
        let varItem = cloneDeep(this.props.merchantVar);
        varItem.purchaseType = e.target.value;

        this.props.onChange(varItem);
    }

    handleSubNote = (subequipmentId) => (note) => {
        let varItem = cloneDeep(this.props.merchantVar);
        let eqp = varItem.subequipment.find((e, i) => e.equipmentId == subequipmentId);
        if (!eqp) return;

        eqp.notes = note;
        this.props.onChange(varItem);
    }

    handleSubFeeChange = (subequipmentId, feeId, updatedValue) => {
        if (!feeId) return; // happens when unselecting a subequipment
        let varItem = cloneDeep(this.props.merchantVar);
        let eqp = varItem.subequipment.find((e, i) => e.equipmentId == subequipmentId);
        if (!eqp) return;

        let fee = eqp.fees.find((fee, i) => fee.feeId == feeId);
        //console.log('in handleSubFeeChange for plan ' + planId);

        const isValidValue = /^0*\.0*$/.test(updatedValue.value)
        if(isValidValue || (updatedValue.floatValue === undefined ||isNaN(updatedValue.floatValue)))
        {
            fee.merchantPrice = null;
        }
        else
        {
            fee.merchantPrice = updatedValue.floatValue;
        }
        this.props.onChange(varItem);
    }

    handleFeeChange = (planId, feeId, updatedValue) => {
        //console.log('fee change for plan ' + planId + ' fee ' + feeId);
        let varItem = cloneDeep(this.props.merchantVar);
        let fee = varItem.fees.find((fee, i) => fee.feeId == feeId); 

        // can be just switching plans, so another plan's fees are triggering a value change, but don't need to update state in this case
        if (!fee) return;

        const isValidValue = /^0*\.0*$/.test(updatedValue.value)
        if(isValidValue || (updatedValue.floatValue === undefined ||isNaN(updatedValue.floatValue)))
        {
            fee.merchantPrice = null;
        }
        else
        {
            fee.merchantPrice = updatedValue.floatValue;
        }
        //console.log(e.target.value);
        //console.log(fee.merchantPrice);
        this.props.onChange(varItem);
    }

    setVarSubequipment(varItem, equipmentId, selected) {
        let subequip = this.props.varItem.subequipment.find((e, i) => e.equipmentId == equipmentId);
        varItem.subequipment = varItem.subequipment || [];

        if (!subequip) return;

        if (selected) {
            let sub = this.props.createNewEquipment(equipmentId, selected, true, false, varItem.equipmentId);
            varItem.subequipment.push(sub);
        }
        else {
            varItem.subequipment = varItem.subequipment.filter((e, i) => e.equipmentId != equipmentId);
        }

    }

    selectSubequipment = (e) => {
        let equipmentId = e.target.value;
        let selected = e.target.checked;
        let varItem = cloneDeep(this.props.merchantVar);
        this.setVarSubequipment(varItem, equipmentId, selected)
        this.props.onChange(varItem);
    }

    handleChange = (e) => {
        //console.log(e.target.name);
        let varItem = cloneDeep(this.props.merchantVar);
        let itemToSet, itemKey;
        let strName = e.target.name;
        if (strName.startsWith(this.props.varItem.name + "_"))
            strName = strName.substr(this.props.varItem.name.length + 1);

        if (strName.indexOf('__') > 0) {
            let keyList = compact(strName.split('__'));
            itemToSet = keyList.reduce((prev, curItem, idx) => {
                if (idx < keyList.length - 1) {
                    return prev[curItem];
                }
                return prev;
            }, varItem);
            itemKey = keyList[keyList.length - 1];
        }
        else {
            itemToSet = varItem;
            itemKey = strName;
        }
        //console.log('form item was ' + e.target.name);
        //console.log('updating ' + itemKey + ' property of the ' + itemToSet + ' object');
        let newVal = e.target.value;
        if (e.target.type && e.target.type === 'checkbox')
            newVal = e.target.checked;

        itemToSet[itemKey] = newVal;

        this.props.onChange(varItem);
    }

    toggleSection = (isExpanded = !this.state.isExpanded) => this.setState({ isExpanded });

    get equipmentFiles() {
        const { varItem: { equipmentOptions } } = this.props;
        return transform(equipmentOptions, (acc, item, key) => {
            if (item.dataType === 'file') {
                acc[key] = item;
            }
        });
    };

    renderPurchaseTypes = (varItem, merchantVar) => {
        return (<div className="gateway__top">
            {
                Object.keys(varItem.purchaseTypes).map((pt, idx) => {
                    return (
                        <div className="gateway__top__item" key={idx}>
                            <div className="spc--bottom--sml">
                                <input type="radio" className="input--check" name={varItem.name + "_purchaseType"} id={varItem.name + "_" + pt + "_pt"} onChange={this.selectPurchaseType}
                                    value={pt} checked={merchantVar.purchaseType === pt} />
                                <label className="label label--big" htmlFor={varItem.name + "_" + pt + "_pt"}>{varItem.purchaseTypes[pt]}</label>
                            </div>
                            {pt === 'rollover' && (
                                <Fragment>
                                    <div className="gateway__top__separator">
                                    </div>
                                    <div>
                                        <div className="row row-align-middle">
                                            <div className="col col-sml-12 col-med-5">
                                                <label>Equipment ID</label>
                                            </div>
                                            <div className="col col-sml-12 col-med-7">
                                                <input type="text" className="input input--med w--max--300" placeholder="Serial Number" value={merchantVar.serialNumber} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    );
                })
            }
        </div>
        );
    }   

    renderPaymentSchedules = (varItem, merchantVar) => {
        return (
            <div id={`${varItem.name}_paymentSchedule_wrapper`} className="gateway__top">
                <div className="gateway__top__item">
                    <div className="row">
                        {Object.keys(varItem.paymentSchedules).map((value, index) => {
                            const uniqueKey = `${varItem.name}_paymentSchedule_${index}`;
                            return (
                                <div className="col spc--bottom--sml" key={uniqueKey}>
                                    <input
                                        id={uniqueKey}
                                        type="radio"
                                        className="input--radio"
                                        name="paymentSchedule"
                                        value={value}
                                        checked={merchantVar.paymentSchedule === value}
                                        onChange={this.handleChange}
                                    />
                                    <label htmlFor={uniqueKey}>{varItem.paymentSchedules[value]}</label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

    renderNotes = (varItem, merchantVar) => {
        return (
            <div className="gateway__top">
                <div className="gateway__top__item">
                    <textarea id={`${varItem.name}_notes`} className="input input--textarea spc--bottom--sml" rows="10" placeholder="Notes" name={varItem.name + '_notes'} value={merchantVar.notes} onChange={this.handleChange}></textarea>
                </div>
            </div>
        );
    }

    render() {
        const { isExpanded } = this.state;
        let { varItem, merchantVar, downloadPrefilledVarSheet } = this.props;
        if (!merchantVar) return null;
        const errorList = merchantVar.errors;        
        // make sure error object is valid -- otherwise ignore it
        // this is because it seems JSON.stringify doesn't handle this object properly and saves it empty
        let errorListPaths = (errorList && errorList.length > 0) && errorList.map(e => e.path);
        const file = get(merchantVar, 'attachment.file');
        
        return (
            <Fragment>
                <div className="gateway__list__item" onClick={() => this.toggleSection()}>
                    <div className="flex--primary">
                        <div className="gateway__list__radio-button__wrapper">
                            <input
                                className="gateway__list__radio-button"
                                type="checkbox"
                                name={`${merchantVar.equipmentId}.isSelected`}
                                id={`${merchantVar.equipmentId}.isSelected`}
                                value={merchantVar.equipmentId}
                                checked={merchantVar.isSelected}
                                onChange={this.varSelect}
                                />
                            <label htmlFor={`${merchantVar.equipmentId}.isSelected`}>{merchantVar.isSelected ? "Selected" : "Select"}</label>
                        </div>
                        <div>
                            <h2>{varItem.name}</h2>
                            <p>{varItem.description}</p>
                        </div>
                    </div>
                    <div className="card--tertiary__expand spc--right--sml">
                        <i className={`icon icon--arrow icon--xsml icon--arrow--right--primary${this.state.isExpanded ? ' rotate--90' : ''}`}></i>
                    </div>
                </div>
                {merchantVar.isSelected && errorListPaths ? (
                    <Fragment>
                        <div className="note note--warning spc--top--sml">
                            <ul> {errorList.map((elem, i) => {
                                let errorPrefix = '';
                                let pathArr = elem.path.split('.');
                                if (pathArr[0] == 'fees') {
                                    errorPrefix = merchantVar.fees[pathArr[1]].feeName;
                                } else if (pathArr[0] == 'subequipment') {
                                    let equipId = merchantVar.subequipment[pathArr[1]].equipmentId;
                                    errorPrefix = varItem.subequipment.find(s => s.equipmentId == equipId).name;
                                }
                                errorPrefix = errorPrefix && '**' + errorPrefix + '**: ';

                                return <ClickableErrorMessage index={i} errorPrefix={errorPrefix} elem={elem} scrollTo={this.scrollTo} focusField={this.focusField} />;
                            })}</ul>
                        </div>
                    </Fragment>
                ) : null}
                {isExpanded && (
                    <Fragment>
                        <div className="spc--top--med separator separator--grey1 spc--bottom--med"></div>
                        {!isEmpty(varItem.purchaseTypes) && (
                            <section className="spc--bottom--lrg">
                                <h3 className="spc--bottom--med">Purchase Type</h3>
                                {this.renderPurchaseTypes(varItem, merchantVar)}                            
                            </section>
                        )}
                        <section className="spc--bottom--lrg">
                            <AddonListComponent
                                gateway={{
                                    ...varItem, equipmentOptions: transform(varItem.equipmentOptions, (acc, item, key) => {
                                        if (item.dataType !== 'file') {
                                            acc[key] = item;
                                        }
                                    })
                                }}
                                merchantGateway={merchantVar}
                                availablePurchasePlans={[]}
                                selectSubequipment={this.selectSubequipment}
                                handleSubOptionSelect={this.subOptionSelect}
                                handleSubOptionChange={this.subOptionChange}
                                handleSubOptionMoreInfo={this.suboptionSetMoreInfo}
                                handleSubFeeChange={this.handleSubFeeChange}
                                handleSubNote={this.handleSubNote}
                                openCloseModal={this.props.openCloseModal}
                                handleGatewayOptionSelect={this.optionSelect}
                                handleGatewayChange={this.handleChange}
                                handleOptionMoreInfo={this.optionSetMoreInfo}
                                handleFeeChange={this.handleFeeChange}
                                classNames={{}}
                            />
                        </section>
                        <section className="spc--bottom--lrg">
                            <h3 className="spc--bottom--med">Notes</h3>
                            {this.renderNotes(varItem, merchantVar)}
                        </section>
                        {!isEmpty(this.equipmentFiles) && (
                            <section className="">
                                <h3 className="spc--bottom--med">File Attachment</h3>
                                <button onClick={downloadPrefilledVarSheet} className="btn btn--med btn--primary spc--bottom--med">Generate Var Sheet</button>
                                <EquipmentFileUpload equipmentFiles={this.equipmentFiles} file={file} onDropFile={this.onDropFile} handleRemoveFile={this.handleRemoveFile} />
                            </section>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );        
    }
}

VarListItemComponent.propTypes = {
    merchantVar: PropTypes.object,
    varItem: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    varSelect: PropTypes.func.isRequired,
    downloadPrefilledVarSheet: PropTypes.func.isRequired,
}

export default VarListItemComponent;