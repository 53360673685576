import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { split } from 'lodash';
import { parse } from 'query-string';


import { principalService, appService } from '../../services';

import { Notification } from '../../common/components/notifications';
import { withLoader } from '../../common/components';
import { parseError } from '../../common/utilities';
import { withCancelable } from '../../common/components/cancelable';

class SamlLoginComponent extends Component {
	constructor(props) {
		super(props);
		const queryParams = parse(window.location.search);
		let redirectParam = queryParams["redirectTo"];
		if (!!redirectParam) {
			sessionStorage.setItem('saml-redirect', redirectParam);
		}

		this.state = {
			errorMessage: null,
			redirectTo: redirectParam
		};
	}

	async componentWillMount() {
		try {
			let currentAuthUser = await Auth.currentAuthenticatedUser();			
			if (principalService.get() && currentAuthUser){
				principalService.clear();
				// cleared the local storage - put back redirect url
				if (!!this.state.redirectTo) {
					sessionStorage.setItem('saml-redirect', this.state.redirectTo);
				}
			}

			if (!principalService.get() && currentAuthUser) {
				try {
					const {
						signInUserSession: {
							idToken: { jwtToken, payload: {email} }							
						},
					} = currentAuthUser;

					this.props.showLoader(true);
					try {
						await appService.login(jwtToken, email);
						this.props.showLoader(false);
						this.redirect();
					} catch (err) {
						if (err && err.isCanceled) {
							return;
						}
						const { stack } = parseError(err);
						this.props.showLoader(false);
						if (
							err &&
							err.ex &&
							err.ex.response &&
							(err.ex.response.status === 401 || err.ex.response.status === 403)
						) {
							this.setState({
								errorMessage: 'You are not authorized to access the page. Contact customer support: cs@cardknox.com',
							});
						} else {
							this.setState({ errorMessage: stack });
						}
					}
				} catch (err) {
					Auth.signOut();					
					await Auth.federatedSignIn({ provider: 'prd-fps'});
				}
			}
		} catch (ex) {
			Auth.signOut();			
			await Auth.federatedSignIn({ provider: 'prd-fps'});
		}
	}

	async redirect() {
		const { history, location } = this.props;
		let redirectUrl = sessionStorage.getItem('saml-redirect');
        let search = '';
        let additionalState;

        // hide loader before redirect
        this.props.showLoader(false);
        if (location.hash) {
			[redirectUrl, search] = split(location.hash, '?');
			const queryParams = parse(location.hash);
			redirectUrl = (queryParams && queryParams["state"]) || redirectUrl;
        }

        history.push({
            pathname: redirectUrl,
            search,
            ...(additionalState || {}),
        });
    }

	render() {
		const { errorMessage } = this.state;
		return (
			<div>
				Logging in...
				<Notification ref={el => (this.notification = el)} />
				{errorMessage ? (
					<div className="spc--top--sml membership__spacer type--color--warning">{errorMessage}</div>
				) : null}
			</div>
		);
	}
}

SamlLoginComponent.propTypes = {
	showLoader: PropTypes.func,
	location: PropTypes.object,
    history: PropTypes.object
};

export default withCancelable(withLoader(SamlLoginComponent));
