import React, { Component, Fragment } from 'react';
import { map, startCase, find } from 'lodash';
import { func } from 'prop-types';

import { appService } from '../../../services';

class GoPlusTierPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            tiers: [{ value: '', label: 'Please Select...', industry: '' }],
            achTiers: [],
            tierName: '',
        };
    }

    get getSelectedTier() {
        const { tierName, tiers } = this.state;

        return find(tiers, ({ value }) => tierName === value);
    }

    componentDidMount() {
        this.loadGoPlusTiers();
    }

    loadGoPlusTiers = () => {
        const { closeModal, addNotification, isAchqOnly } = this.props;

        this.showLoader(true);
        appService.getGoPlusTiers(this.props.appId, true)
            .then(tiers => {                
                isAchqOnly ? this.mapTiersToState(tiers) : this.mapAchTiersToState(tiers);
            })
            .catch(err => {
                this.showLoader();
                closeModal();
                addNotification({
                    success: false,
                    message: 'An error occurred: ' + err,
                });
            });
        !isAchqOnly && appService.getGoPlusTiers(this.props.appId)
            .then(this.mapTiersToState)
            .catch(err => {
                this.showLoader();
                closeModal();
                addNotification({
                    success: false,
                    message: 'An error occurred: ' + err,
                });
            });
    };

    mapTiersToState = ({ tiers }) => {
        this.setState({
            tiers: [...this.state.tiers, ...map(tiers, ([key, industry], value) => ({ value, label: startCase(key), industry }))]
        }, this.showLoader);
    };

    mapAchTiersToState = ({ tiers }) => {
        const keys = Object.keys(tiers);
        this.setState({
            achTiers: [...this.state.achTiers, ...keys]
        });
    };

    confirm = () => {
        const { closeModal, setTierName,setTiers, setIsECommerce, onConfirm } = this.props;
        setTierName(this.state.tierName);
        setTiers(this.state.tiers)
        setIsECommerce(this.getSelectedTier.industry === 'ecommerce');
        onConfirm(this.state.tierName, this.state.tiers);
        closeModal();
    };

    showLoader = (isLoading = false) => this.setState({ isLoading });

    renderPopupBody = () => (
        <div className="row">
            <div className="col col-sml-12">
                {this.state.isLoading ? this.renderLoader() : null}
                {this.renderTierDropdown()}
            </div>
        </div>
    );

    handleChange = ({ target: { name, value } }) => this.setState({ [name]: value });

    renderTierOptions = () => map(this.state.tiers, ({ value, label }) => <option key={`${value}.${label}`} value={value}>{label}</option>);

    renderTierDropdown = () => (
        <select
            className="input input--med input--select"
            id="tierName"
            name="tierName"
            value={this.getSelectedTier.value}
            onChange={this.handleChange}
            disabled={this.state.isLoading}
        >
            {this.renderTierOptions()}
        </select>
    );

    renderLoader = () => (
        <div className="loader--progress"></div>
    );

    render() {
        const { tierName, achTiers } = this.state;
        const { isAchqOnly } = this.props;
        return (
            <Fragment>
                <div className="popup__header">
                    <div className="popup__header__title">Select Tier</div>
                </div>
                <div className="popup__body">
                    {this.renderPopupBody()}
                    {!isAchqOnly && achTiers.includes(tierName) && appService.isDev() &&
                        <div className="note note--default spc--top--med">
                            Selecting this tier will create an ACHQ account in addition to the Cardknox Go account.
                        </div>
                    }
                </div>
                <div className="popup__footer--styled ">
                    <div className="type--right">
                        <button disabled={!tierName} onClick={this.confirm} className="btn btn--med btn--primary spc--right--xsml">Confirm</button>
                    </div>
                </div>
            </Fragment>
        );
    }
}

GoPlusTierPopup.propTypes = {
    closeModal: func.isRequired,
    addNotification: func.isRequired,
    setTierName: func.isRequired,
    setTiers: func.isRequired,
    onConfirm: func.isRequired
};

export default GoPlusTierPopup;