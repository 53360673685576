import { addressValidation } from './address.validation';
import { url, ascii, maxLength, digitLength, addressPOBox, ownershipTypeCorpName, phoneNumber, email } from './validators';
import { isNumber } from 'lodash'
import moment from 'moment';
const outputFormat = process.env.REACT_APP_DISPLAY_DATE_FORMAT;

const ownershipType = (val) => val ? val !== "Unknown" : false;
const secondaryBankAccountInformationRequired = (val, ctx) =>  !(ctx.enableSecondaryBankAccountInfo && (!!val === false));
const ownershipPercentageValue = (val, ctx) =>  isNumber(val) ? val > 0 && val <= 100 : true;
const number = val =>  !val ? true :  val > 0;

const alternativeBankAccountInformationRequired = (val, ctx) => !(ctx.enableAlternativeBankAccountInfo && (!!val === false)); 
const is18YearsOld = val => {
    if (!val) {
        return true;
    }

    return moment(new Date(val), outputFormat, false).isBefore(moment().subtract(18, 'years')) || moment(new Date(val), outputFormat, false).isSame(moment().subtract(18, 'years'));
};
export const AchInvalidTemplate = {
    businessInformation: {
        businessEmail: {
            type: String,
            use: { ascii, length: maxLength(128), email: email(true) },
            message: {
                length: '[**Contact Email**](javascript:void) is invalid. Maximum number of characters allowed is 128.',
                ascii: '[**Contact Email**](javascript:void) contains invalid characters',
                email: '[**Contact Email**](javascript:void) is invalid',
            },
        },
        businessPhone: {
            use: { phoneNumber, ascii },
            type: String,
            message: {
                ascii: '[**Business Phone Number**](javascript:void) contains invalid characters',
                phoneNumber: '[**Business Phone Number**](javascript:void) is invalid',

            },
        },
        businessFax: {
            use: { ascii, phoneNumber },
            type: String,
            message: {
                phoneNumber: '[**Business Fax Number**](javascript:void) is invalid',
                ascii: '[**Business Fax Number**](javascript:void) contains invalid characters',
            },
        },
        website: {
            use: { 
                ascii, 
                length: maxLength(64) ,
                url
            },
            type: String,
            message: {
                length: '[**Website**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
                url: '[**Website**](javascript:void) is invalid',
                ascii: '[**Website**](javascript:void) contains invalid characters',
            },
        },
        businessAddress: addressValidation('Business ', 64, 64),
        ownershipType: {
            use: { 
                ownershipTypeCorpName: ownershipTypeCorpName('SoleOwnership') 
            },
            type: String,
            message: {
                ownershipTypeCorpName: '[**Business Type**](javascript:void) Sole Ownership is not allowed on Incorporated Business or Limited Liability Company',
            }
        },
    },
    corporateName: {
        use: { ascii, length: maxLength(128) },
        type: String,
        message: {
            length: '[**Corporate Name**](javascript:void) is invalid. Maximum number of characters allowed is 128.',
            ascii: '[**Corporate Name**](javascript:void) contains invalid characters',
        },
    },
    contactPhoneNumber: {
        use: { phoneNumber, ascii },
        type: String,
        message: {
            ascii: '[**Contact Phone Number**](javascript:void) contains invalid characters',
            phoneNumber: '[**Contact Phone Number**](javascript:void) is invalid',

        },
    },
    corporateAddress: addressValidation('Mailing ', 128, 50),
    taxID: {
        use: { ascii, digitLength: digitLength(9) },
        type: String,
        message: {
            digitLength: '[**Tax ID**](javascript:void) is invalid',
            ascii: '[**Tax ID**](javascript:void) contains invalid characters',
        },
    },
    dba: {
        use: { ascii },
        type: String,
        message: {
            ascii: '[**DBA**](javascript:void) contains invalid characters'
        },
    },
    signerInformation: {
        firstName: {
            use: { ascii, length: maxLength(64) },
            type: String,
            message: {
                length: '[**Signer First Name**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
                ascii: '[**Signer First Name**](javascript:void) contains invalid characters',
            }
        },
        lastName: {
            use: { ascii, length: maxLength(64) },
            type: String,
            message: {
                length: '[**Signer Last Name**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
                ascii: '[**Signer Last Name**](javascript:void) contains invalid characters',
            }
        },
        title: {
            use: { ascii, length: maxLength(64) },
            type: String,
            message: {
                length: '[**Signer Title**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
                ascii: '[**Signer Title**](javascript:void) contains invalid characters',
            }
        },
        address: {
            ...addressValidation('Signer ', 64, 32, 'Home '),
            streetAddress: {
                use: { ascii, length: maxLength(64), addressPOBox },
                type: String,
                message: {
                    addressPOBox: `[**Signer Home Address**](javascript:void) cannot be a PO Box address`,
                    ascii: `[**Signer Home Address**](javascript:void) contains invalid characters`,
                    length: `[**Signer Home Address**](javascript:void) is invalid. Maximum number of characters allowed is 64.`,
                }
            },
        },
        phoneNumber: {
            use: { phoneNumber, ascii },
            type: String,
            message: {
                ascii: '[**Signer Phone Number**](javascript:void) contains invalid characters',
                phoneNumber: '[**Signer Phone Number**](javascript:void) is invalid',
            },
        },
        cellPhoneNumber: {
            use: { phoneNumber, ascii },
            type: String,
            message: {
                ascii: '[**Signer Cell Number**](javascript:void) contains invalid characters',
                phoneNumber: '[**Signer Cell Number**](javascript:void) is invalid',
            },
        },
        socialSecurityNumber: {
            type: String,
            use: { ascii,length:digitLength(9) },
            message: {
                length: '[**Signer Social Security Number**](javascript:void) is invalid',
                ascii: '[**Signer Social Security Number**](javascript:void) contains invalid characters'
            },
        },
        ownershipPercentage : {
            use: { ownershipPercentageValue } ,
            type: Number,
            message: {
                ownershipPercentageValue: '[**Ownership Percentage**](javascript:void) must be a valid ownership percent'
            }
        },
        dateOfBirth: {
            type: String,
            use: { is18YearsOld },
            message: {
                is18YearsOld: '[**Date of Birth**](javascript:void) must be at least 18 years old',
            },
        },
        email: {
            type: String,
            use: { ascii, length: maxLength(128), email: email(false) },
            message: {
                length: '[**Signer Email**](javascript:void) is invalid. Maximum number of characters allowed is 128.',
                ascii: '[**Signer Email**](javascript:void) contains invalid characters',
                email: '[**Signer Email**](javascript:void) is invalid',
            },
        },
    },
    goodsOrServicesDescription: {
        use: { length: maxLength(64), ascii },
        type: String,
        message: {
            length: '[**Goods/Services Sold**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            ascii: '[**Goods/Services Sold**](javascript:void) contains invalid characters',
        },
    },
    bankAccountInformation: {
        bankName: {
            use: { 
                ascii, 
                length: maxLength(64) 
            },
            type: String,
            message: {
                ascii: '[**Bank Name**](javascript:void) contains invalid characters',
                length: '[**Bank Name**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            },
        },
        routingNumber: {
            use: { 
                ascii, 
                length: maxLength(64) 
            },
            type: String,
            message: {
                ascii: '[**Bank Routing #**](javascript:void) contains invalid characters',
                length: '[**Bank Routing #**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            },
        },
        accountNumber: {
            use: { 
                ascii, 
                accountNumber: digitLength(6, 17) 
            },
            type: String,
            message: {
                ascii: '[**Bank Account #**](javascript:void) contains invalid characters',
                accountNumber: '[**Bank Account #**](javascript:void) is invalid',
            },
        },
        secondaryBankName: {
            use: { 
                ascii, 
                length: maxLength(64) 
            },
            type: String,
            message: {
                length: '[**Secondary Bank Name**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
                ascii: '[**Secondary Bank Name**](javascript:void) contains invalid characters',
            },
        },
        secondaryRoutingNumber: {
            use: { 
                ascii, 
                length: maxLength(64) 
            },
            type: String,
            message: {
                ascii: '[**Secondary Bank Routing #**](javascript:void) contains invalid characters',
                length: '[**Secondary Bank Routing #**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            },
        },
        secondaryAccountNumber: {
            use: { 
                ascii, 
                accountNumber: digitLength(6, 17) 
            },
            type: String,
            message: {
                accountNumber: '[**Secondary Bank Account #**](javascript:void) is invalid',
                ascii: '[**Secondary Bank Account #**](javascript:void) contains invalid characters',
            },
        },
        alternateBankName: {
            use: { 
                ascii, 
                length: maxLength(64) 
            },
            type: String,
            message: {
                ascii: '[**Alternative Bank Name**](javascript:void) contains invalid characters',
                length: '[**Alternative Bank Name**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            },
        },
        alternateRoutingNumber: {
            use: { 
                ascii, 
                length: maxLength(64) 
            },
            type: String,
            message: {
                length: '[**Alternative Bank Routing #**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
                ascii: '[**Alternative Bank Routing #**](javascript:void) contains invalid characters',
            },
        },
        alternateAccountNumber: {
            use: { 
                ascii, 
                accountNumber: digitLength(6, 17) 
            },
            type: String,
            message: {
                ascii: '[**Alternative Bank Account #**](javascript:void) contains invalid characters',
                accountNumber: '[**Alternative Bank Account #**](javascript:void) is invalid',
            },
        },
    },
    accountNotes: {
        use: { ascii, length: maxLength(1500) },
        type: String,
        message: {
            length: '[**Other Notes**](javascript:void) are invalid. Maximum number of characters allowed is 1500.',
            ascii: '[**Other Notes**](javascript:void) contain invalid characters',
        },
    },
    generalTransactionInfo:{
        maxPeriod:{
            use: {length: maxLength(9), number},
            type:String,
            message:{
                length: '[**Monthly Check Amount**](javascript:void) is invalid.',
                number: '[**Monthly Check Amount**](javascript:void) must be greater than 0.'
            }
        },
        maxPeriodAmount:{
            use: {length: maxLength(9), number},
            type:String,
            message:{
                length: '[**Max Monthly Volume**](javascript:void) is invalid.',
                number: '[**Max Monthly Volume**](javascript:void) must be greater than 0.'
            }
        },
        maxDaily:{
            use: {length: maxLength(9), number},
            type:String,
            message:{
                length: '[**Daily Check Amount**](javascript:void) is invalid.',
                number: '[**Daily Check Amount**](javascript:void) must be greater than 0.'
            }
        },
        maxDailyAmount:{
            use: {length: maxLength(9), number},
            type:String,
            message:{
                length: '[**Max Daily Volume**](javascript:void) is invalid.',
                number: '[**Max Daily Volume**](javascript:void) must be greater than 0.'
            }
        },
        maxSingle:{
            use: {length: maxLength(9), number},
            type:String,
            message:{
                length: '[**Max Single**](javascript:void) is invalid.',
                number: '[**Max Single**](javascript:void) must be greater than 0.'
            }
        },
        averageTransactionAmount:{
            use: {length: maxLength(9), number},
            type:String,
            message:{
                length: '[**Expected Average Amount**](javascript:void) is invalid.',
                number: '[**Expected Average Amount**](javascript:void) must be greater than 0.'
            }
        }
    }
}

export const AchRequiredTemplate = {
    dba: {
        required: true,
        type: String,
        message: '[**DBA**](javascript:void)',
    },
    contactPhoneNumber: {
        required: true,
        type: String,
        message: '[**Contact Phone Number**](javascript:void)',
    },
    businessContactName: {
        required: true,
        type: String,
        message: '[**Contact Name**](javascript:void)',
    },
    businessStartDate: {
        required: true,
        type: String,
        message: '[**Business Start Date**](javascript:void)',
    },
    businessInformation: {
        businessPhone: {
            required: true,
            type: String,
            message: '[**Business Phone Number**](javascript:void)',
        },
        website: {
            required: true,
            type: String,
            message: '[**Website**](javascript:void)',
        },
        businessEmail: {
            required: true,
            type: String,
            message: '[**Contact Email**](javascript:void)',
        },
        businessAddress: {
            streetAddress: {
                required: true,
                type: String,
                message: '[**Business Address**](javascript:void)',
            },
            city: {
                required: true,
                type: String,
                message: '[**Business City**](javascript:void)',
            },
            state: {
                required: true,
                type: String,
                message: '[**Business State**](javascript:void)',
            },
            zip: {
                required: true,
                type: String,
                message: '[**Business Zip**](javascript:void)',
            },
        },
        ownershipType: {
            use:{ ownershipType },
            type: String,
            message: {
                ownershipType: '[**Business Type**](javascript:void) must be selected',
            }
        },
    },
    corporateName: {
        required: true,
        type: String,
        message: '[**Corporate Name**](javascript:void)',

    },
    corporateAddress: {
        streetAddress: {
            required: true,
            type: String,
            message: '[**Mailing Address**](javascript:void)',
        },
        city: {
            required: true,
            type: String,
            message: '[**Mailing City**](javascript:void)',
        },
        state: {
            required: true,
            type: String,
            message: '[**Mailing State**](javascript:void)',
        },
        zip: {
            required: true,
            type: String,
            message: '[**Mailing Zip**](javascript:void)',
        },
    },
    signerInformation: {
        firstName: {
            required: true,
            type: String,
            message: '[**Signer First Name**](javascript:void)',
        },
        lastName: {
            required: true,
            type: String,
            message: '[**Signer Last Name**](javascript:void)',
        },
        title: {
            required: true,
            type: String,
            message: '[**Signer Title**](javascript:void)',
        },
        address: {
            streetAddress: {
                required: true,
                type: String,
                message: {
                    required: '[**Signer Home Address**](javascript:void)',
                } 
            },
            city: {
                required: true,
                type: String,
                message: '[**Signer City**](javascript:void)',
            },
            state: {
                required: true,
                type: String,
                message: '[**Signer State**](javascript:void)',
            },
            zip: {
                required: true,
                type: String,
                message: '[**Signer Zip**](javascript:void)',
            },
        },
        ownershipPercentage : {
            required: true,
            type: Number,
            message: '[**Ownership Percentage**](javascript:void)',
        },
        email: {
            required: true,
            type: String,
            message: '[**Signer Email**](javascript:void)',
        },
        phoneNumber: {
            required: true,
            type: String,
            message: '[**Signer Phone Number**](javascript:void)',
        },
        socialSecurityNumber: {
            required: true,
            type: String,
            message: '[**Signer Social Security Number**](javascript:void)',
        },
        dateOfBirth: {
            required: true,
            type: String,
            message: '[**Signer DOB**](javascript:void)',
        }
    },
    goodsOrServicesDescription: {
        required: true,
        type: String,
        message: '[**Goods/Services Sold**](javascript:void)',
    },
    taxID: {
        required: true,
        type: String,
        message: '[**Tax ID**](javascript:void)',
    },
    bankAccountInformation: {
        bankName: {
            required: true,
            type: String,
            message: '[**Bank Name**](javascript:void)',
            },
        accountNumber: {
            required: true,
            type: String,
            message: '[**Bank Account Number**](javascript:void)',
        },
        routingNumber: {
            required: true,
            type: String,
            message: '[**Bank Routing Number**](javascript:void)',
        },
        secondaryBankName: {
            use: { secondaryBankAccountInformationRequired },
            type: String,
            message: {
                secondaryBankAccountInformationRequired: '[**Secondary Bank Name**](javascript:void)'
            }
        },
        secondaryAccountNumber: {
            use: { secondaryBankAccountInformationRequired },
            type: String,
            message: {
                secondaryBankAccountInformationRequired: '[**Secondary Bank Account Number**](javascript:void)'
            }
        },
        secondaryRoutingNumber: {
            use: { secondaryBankAccountInformationRequired },
            type: String,
            message: {
                secondaryBankAccountInformationRequired: '[**Secondary Bank Routing Number**](javascript:void)'
            }
        },    
        alternateBankName: {
            use: { alternativeBankAccountInformationRequired },
            type: String,
            message: {
                alternativeBankAccountInformationRequired: '[**Alternative Bank Name**](javascript:void)'
            }
        },
        alternateAccountNumber: {
            use: { alternativeBankAccountInformationRequired },
            type: String,
            message: {
                alternativeBankAccountInformationRequired: '[**Alternative Bank Account Number**](javascript:void)'
            }
        },
        alternateRoutingNumber: {
            use: { alternativeBankAccountInformationRequired },
            type: String,
            message: {
                alternativeBankAccountInformationRequired: '[**Alternative Bank Routing Number**](javascript:void)'
            }
        }
    },
    hasExistingFidelityAccount: {
        type: Boolean,
        required: true,
        message: '[**The Signer Or Business Has, Or Has Previously Had, An Account With Fidelity**](javascript:void)',
    },
    generalTransactionInfo:{
        maxPeriod:{
            required: true,
            type: String,
            message: '[**Monthly Check Amount**](javascript:void)'
        },
        maxPeriodAmount:{
            required: true,
            type: String,
            message: '[**Max Monthly Volume**](javascript:void)'
        },
        maxDaily:{
            required: true,
            type: String,
            message: '[**Daily Check Amount**](javascript:void)'
        },
        maxDailyAmount:{
            required: true,
            type: String,
            message: '[**Max Daily Volume**](javascript:void)'
        },
        maxSingle:{
            required: true,
            type: String,
            message: '[**Max Single**](javascript:void)'
        },
        averageTransactionAmount:{
            required: true,
            type: String,
            message: '[**Expected Average Amount**](javascript:void)'
        }
    }
}
