import React, { Fragment, Component } from "react";
import { cloneDeep, compact, isNaN } from "lodash";
import { object, func, bool } from "prop-types";
import { defaultImplicitParse, defaultReactOutput } from "simple-markdown";

import GatewayPurchasePlanComponent from "./gateway-purchase-plan";
import AddonListComponent from "./addon-list";
import FrontendProcessorComponent from "./frontend-processor";
import { focusField, scrollTo } from "../../common/utilities";

class SoftwareListItemComponent extends Component {
    constructor(props) {
        super(props);

        const { isExpanded } = props;

        this.state = {
            isExpanded,
        };

        this.scrollTo = scrollTo(props.software);
        this.focusField = focusField(props.software);
    }

    softwareSelect = (e, unselectOther = true) => {
        let software;
        if (
            this.props.merchantSoftware &&
            this.props.merchantSoftware.isSelected &&
            e.target.value === this.props.software.equipmentId &&
            !e.target.checked
        ) {
            software = cloneDeep(this.props.merchantSoftware);
            software.isSelected = false;
        } else if (
            (!this.props.merchantSoftware ||
                !this.props.merchantSoftware.isSelected) &&
            e.target.value === this.props.software.equipmentId &&
            e.target.checked
        ) {
            software =
                cloneDeep(this.props.merchantSoftware) ||
                this.props.createNewEquipment(
                    this.props.software.equipmentId,
                    true
                );
            software.isSelected = true;
            this.toggleSection(true);
        }

        if (software) {
            this.props.softwareSelect(
                software,
                software.isSelected && unselectOther
            );
        }
    };

    subOptionSelect = (equipmentId) => (e) => {
        let software = cloneDeep(this.props.merchantSoftware);
        let eqp = software.subequipment.find(
            (s) => s.equipmentId == equipmentId
        );
        if (!eqp) return;

        if (!eqp.equipmentOptions) {
            eqp.equipmentOptions = {};
        }

        if (e.target.checked) {
            eqp.equipmentOptions[e.target.value] = "1"; // true?
        } else if (Object.keys(eqp.equipmentOptions).includes(e.target.value)) {
            delete eqp.equipmentOptions[e.target.value];
        }

        this.props.onChange(software);
    };
    subOptionChange = (equipmentId) => (e) => {
        let software = cloneDeep(this.props.merchantSoftware);
        let eqp = software.subequipment.find(
            (s) => s.equipmentId == equipmentId
        );
        if (!eqp) return;
        if (!eqp.equipmentOptions) {
            eqp.equipmentOptions = {};
        }
        let inputName = e.target.name;
        let optionName = inputName.substr(
            inputName.indexOf("_equipmentOptions__") +
                "_equipmentOptions__".length
        );
        //console.log(optionName);
        eqp.equipmentOptions[optionName] = e.target.value;

        this.props.onChange(software);
    };

    optionSelect = (e) => {
        let software = cloneDeep(this.props.merchantSoftware);
        if (!software.equipmentOptions) {
            software.equipmentOptions = {};
        }
        if (e.target.checked) {
            software.equipmentOptions[e.target.value] = "1"; // true?
        } else if (
            Object.keys(software.equipmentOptions).includes(e.target.value)
        ) {
            delete software.equipmentOptions[e.target.value];
        }
        this.props.onChange(software);
    };

    optionSetMoreInfo = (optKey, optValue) => (e) => {
        let software = cloneDeep(this.props.merchantSoftware);
        software.equipmentOptions[optKey] = `${optValue}|${e.target.value}`;
        this.props.onChange(software);
    };

    suboptionSetMoreInfo = (equipmentId, optKey, optValue) => (e) => {
        let software = cloneDeep(this.props.merchantSoftware);
        let eqp = software.subequipment.find(
            (s) => s.equipmentId == equipmentId
        );
        if (!eqp) return;

        eqp.equipmentOptions[optKey] = `${optValue}|${e.target.value}`;
        this.props.onChange(software);
    };

    selectPlan = (e) => {
        let software = cloneDeep(this.props.merchantSoftware);
        if (e.target.checked) {
            software.purchasePlanId = e.target.value;
        }
        // add fees
        this.copyFees(
            this.props.software.purchasePlans.find(
                (p, i) => p.planId == e.target.value
            ),
            software
        );

        // reselect subequipment - fees can be plan-dependent
        this.props.merchantSoftware.subequipment.forEach((sub, i) => {
            this.setSoftwareSubequipment(software, sub.equipmentId, false);
            this.setSoftwareSubequipment(software, sub.equipmentId, true);
        });

        this.props.onChange(software);
    };

    selectPurchaseType = (e) => {
        let software = cloneDeep(this.props.merchantSoftware);
        software.purchaseType = e.target.value;

        // add fees
        this.copyFees(
            this.props.software.purchasePlans.find(
                (p, i) => p.planId == software.purchasePlanId
            ),
            software,
            software.purchaseType
        );

        this.props.onChange(software);
    };

    copyFees(sourceObj, destObj, purchaseType) {
        if (!sourceObj || !destObj) {
            return;
        }
        purchaseType = purchaseType || this.props.merchantSoftware.purchaseType;

        destObj.fees = cloneDeep(
            sourceObj.fees.filter((f) => f.purchaseTypes.includes(purchaseType))
        );
        destObj.fees.forEach((fee) => {
            fee.merchantPrice = fee.retailPrice;
        });
    }

    setIncludedTransactions = (planId) => (e) => {
        let software = cloneDeep(this.props.merchantSoftware);
        software.numberOfFreeTransactions = e.target.value;
        this.props.onChange(software);
    };

    handleSubNote = (subequipmentId) => (note) => {
        let software = cloneDeep(this.props.merchantSoftware);
        let eqp = software.subequipment.find(
            (e, i) => e.equipmentId == subequipmentId
        );
        if (!eqp) return;

        eqp.notes = note;
        this.props.onChange(software);
    };

    handleSubFeeChange = (subequipmentId, feeId, updatedValue) => {
        if (!feeId) return; // happens when unselecting a subequipment
        let software = cloneDeep(this.props.merchantSoftware);
        let eqp = software.subequipment.find(
            (e, i) => e.equipmentId == subequipmentId
        );
        if (!eqp) return;

        let fee = eqp.fees.find((fee, i) => fee.feeId == feeId);
        //console.log('in handleSubFeeChange for plan ' + planId);
        
        const isValidValue = /^0*\.0*$/.test(updatedValue.value)
        if(isValidValue || (updatedValue.floatValue === undefined ||isNaN(updatedValue.floatValue)))
        {
            fee.merchantPrice = null;
        }
        else
        {
            fee.merchantPrice = updatedValue.floatValue;
        }
        this.props.onChange(software);
    };

    handleFeeChange = (planId, feeId, updatedValue) => {
        //console.log('fee change for plan ' + planId + ' fee ' + feeId);
        let software = cloneDeep(this.props.merchantSoftware);
        let fee = software.fees.find((fee, i) => fee.feeId == feeId);

        // can be just switching plans, so another plan's fees are triggering a value change, but don't need to update state in this case
        if (!fee) return;

        const isValidValue = /^0*\.0*$/.test(updatedValue.value)
        if(isValidValue || (updatedValue.floatValue === undefined ||isNaN(updatedValue.floatValue)))
        {
            fee.merchantPrice = null;
        }
        else
        {
            fee.merchantPrice = updatedValue.floatValue;
        }
        this.props.onChange(software);
    };

    setSoftwareSubequipment(software, equipmentId, selected) {
        let subequip = this.props.software.subequipment.find(
            (e, i) => e.equipmentId == equipmentId
        );
        software.subequipment = software.subequipment || [];

        if (!subequip) return;

        if (selected) {
            let sub = this.props.createNewEquipment(
                equipmentId,
                selected,
                true,
                false,
                software.equipmentId
            );
            software.subequipment.push(sub);
        } else {
            software.subequipment = software.subequipment.filter(
                (e, i) => e.equipmentId != equipmentId
            );
        }
    }

    selectSubequipment = (e) => {
        let equipmentId = e.target.value;
        let selected = e.target.checked;
        let software = cloneDeep(this.props.merchantSoftware);
        this.setSoftwareSubequipment(software, equipmentId, selected);
        this.props.onChange(software);
    };

    handleTimeChange = (time) => {
        let software = cloneDeep(this.props.merchantSoftware);
        software.batchoutTime = time;
        this.props.onChange(software);
    };

    handleChange = (e) => {
        //console.log(e.target.name);
        let software = cloneDeep(this.props.merchantSoftware);
        let itemToSet, itemKey;
        let strName = e.target.name;
        if (strName.startsWith(this.props.software.name + "_"))
            strName = strName.substr(this.props.software.name.length + 1);

        if (strName.indexOf("_") > 0) {
            let keyList = compact(strName.split("_"));
            itemToSet = keyList.reduce((prev, curItem, idx) => {
                if (idx < keyList.length - 1) {
                    return prev[curItem];
                }
                return prev;
            }, software);
            itemKey = keyList[keyList.length - 1];
        } else {
            itemToSet = software;
            itemKey = strName;
        }
        //console.log('form item was ' + e.target.name);
        //console.log('updating ' + itemKey + ' property of the ' + itemToSet + ' object');
        let newVal = e.target.value;
        if (e.target.type && e.target.type === "checkbox")
            newVal = e.target.checked;

        itemToSet[itemKey] = newVal;

        this.props.onChange(software);
    };

    toggleSection = (isExpanded = !this.state.isExpanded) =>
        this.setState({ isExpanded });

    get softwareSubequipment() {
        return this.props.software.subequipment.filter(
            (e) => e.category.toLowerCase() !== "plugin"
        );
    }
    get pluginSubequipment() {
        return this.props.software.subequipment.filter(
            (e) => e.category.toLowerCase() === "plugin"
        );
    }
    get availablePurchasePlans() {
        if (!this.props.merchantSoftware)
            return this.props.software.purchasePlans;

        return this.props.software.purchasePlans.filter((plan) =>
            plan.purchaseTypes.includes(
                this.props.merchantSoftware.purchaseType
            )
        );
    }

    renderPurchaseTypes = (software, merchantSoftware) => {
        return (
            <div className="gateway__top">
                {Object.keys(software.purchaseTypes).map((pt, idx) => {
                    return (
                        <div className="gateway__top__item" key={idx}>
                            <div className="spc--bottom--sml">
                                <input
                                    type="radio"
                                    className="input--check"
                                    name={software.name + "_purchaseType"}
                                    id={software.name + "_" + pt + "_pt"}
                                    onChange={this.selectPurchaseType}
                                    value={pt}
                                    checked={
                                        merchantSoftware.purchaseType === pt
                                    }
                                />
                                <label
                                    className="label label--big"
                                    htmlFor={software.name + "_" + pt + "_pt"}
                                >
                                    {software.purchaseTypes[pt]}
                                </label>
                            </div>
                            {pt === "rollover" && (
                                <Fragment>
                                    <div className="gateway__top__separator"></div>
                                    <div>
                                        <div className="row row-align-middle">
                                            <div className="col col-sml-12 col-med-5">
                                                <label>Equipment ID</label>
                                            </div>
                                            <div className="col col-sml-12 col-med-7">
                                                <input
                                                    type="text"
                                                    className="input input--med w--max--300"
                                                    placeholder="Serial Number"
                                                    value={
                                                        merchantSoftware.serialNumber
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    renderPaymentSchedules = (software, merchantSoftware) => {
        return (
            <div id={`${software.name}_paymentSchedule_wrapper`} className="gateway__top">
                <div className="gateway__top__item">
                    <div className="row">
                        {Object.keys(software.paymentSchedules).map((value, index) => {
                            const uniqueKey = `${software.name}_paymentSchedule_${index}`;
                            return (
                                <div
                                    className="col spc--bottom--sml"
                                    key={uniqueKey}
                                >
                                    <input
                                        id={uniqueKey}
                                        type="radio"
                                        className="input--radio"
                                        name="paymentSchedule"
                                        value={value}
                                        checked={
                                            merchantSoftware.paymentSchedule ===
                                            value
                                        }
                                        onChange={this.handleChange}
                                    />
                                    <label htmlFor={uniqueKey}>
                                        {software.paymentSchedules[value]}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    renderPlatformAndSettlement = (software, merchantSoftware) => {
        return (
            <FrontendProcessorComponent
                equipment={software}
                merchantEquipment={merchantSoftware}
                handleChange={this.handleChange}
                handleTimeChange={this.handleTimeChange}
                optionSelect={this.optionSelect}
                optionSetMoreInfo={this.optionSetMoreInfo}
            />
        );
    };

    renderNotes = (software, merchantSoftware) => {
        return (
            <div className="gateway__top">
                <div className="gateway__top__item">
                    <textarea
                        id={`${software.name}_notes`}
                        className="input input--textarea spc--bottom--sml"
                        rows="10"
                        placeholder="Notes"
                        name={software.name + "_notes"}
                        value={merchantSoftware.notes}
                        onChange={this.handleChange}
                    ></textarea>
                </div>
            </div>
        );
    };

    render() {
        const { isExpanded } = this.state;
        let { software, merchantSoftware } = this.props;
        if (!merchantSoftware) return null;
        const errorList = merchantSoftware.errors;
        // make sure error object is valid -- otherwise ignore it
        // this is because it seems JSON.stringify doesn't handle this object properly and saves it empty
        let errorListPaths =
            errorList && errorList.length > 0 && errorList.map((e) => e.path);

        return (
            <Fragment>
                <div
                    className="gateway__list__item"
                    onClick={() => this.toggleSection()}
                >
                    <div className="flex--primary">
                        <div className="gateway__list__radio-button__wrapper">
                            <input
                                className="gateway__list__radio-button"
                                type="checkbox"
                                name={`${merchantSoftware.equipmentId}.isSelected`}
                                id={`${merchantSoftware.equipmentId}.isSelected`}
                                value={merchantSoftware.equipmentId}
                                checked={merchantSoftware.isSelected}
                                onChange={this.softwareSelect}
                            />
                            <label
                                htmlFor={`${merchantSoftware.equipmentId}.isSelected`}
                            >
                                {merchantSoftware.isSelected
                                    ? "Selected"
                                    : "Select"}
                            </label>
                        </div>
                        <div>
                            <h2>{software.name}</h2>
                            <p>{software.description}</p>
                        </div>
                    </div>
                    <div className="card--tertiary__expand spc--right--sml">
                        <i
                            className={`icon icon--arrow icon--xsml icon--arrow--right--primary${
                                this.state.isExpanded ? " rotate--90" : ""
                            }`}
                        ></i>
                    </div>
                </div>
                {merchantSoftware.isSelected && errorListPaths ? (
                    <Fragment>
                        <div className="note note--warning spc--top--sml">
                            <ul>
                                {" "}
                                {errorList.map((elem, i) => {
                                    let errorPrefix = "";
                                    let pathArr = elem.path.split(".");
                                    if (pathArr[0] == "fees") {
                                        errorPrefix =
                                            merchantSoftware.fees[pathArr[1]]
                                                .feeName;
                                    } else if (pathArr[0] == "subequipment") {
                                        let equipId =
                                            merchantSoftware.subequipment[
                                                pathArr[1]
                                            ].equipmentId;
                                        errorPrefix = software.subequipment.find(
                                            (s) => s.equipmentId == equipId
                                        ).name;
                                    }
                                    errorPrefix =
                                        errorPrefix &&
                                        "**" + errorPrefix + "**: ";
                                    return (
                                        <li key={i}>
                                            <div
                                                className="anchor"
                                                onClick={() => {
                                                    let elemId = elem.path.replace(/[.]/g, '_');
                                                    this.scrollTo(elemId + '_wrapper');
                                                    this.focusField(elemId);
                                                }}
                                            >
                                                <i className="icon icon--nano icon--text-top icon--alert spc--right--tny"></i>
                                                {defaultReactOutput(defaultImplicitParse(errorPrefix + elem.message))}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Fragment>
                ) : null}
                {isExpanded && (
                    <Fragment>
                        <div className="spc--top--med separator separator--grey1 spc--bottom--med"></div>
                        <section className="spc--bottom--lrg">
                            <h3 className="spc--bottom--med">Purchase Type</h3>
                            {this.renderPurchaseTypes(
                                software,
                                merchantSoftware
                            )}
                        </section>
                        <section className="spc--bottom--lrg">
                            <h3 className="spc--bottom--med required">
                                Payment Schedule
                            </h3>
                            {this.renderPaymentSchedules(
                                software,
                                merchantSoftware
                            )}
                        </section>
                        <section className="spc--bottom--lrg">
                            <h3 className="spc--bottom--med">Plans</h3>
                            <GatewayPurchasePlanComponent
                                gateway={software}
                                merchantGateway={merchantSoftware}
                                availablePurchasePlans={
                                    this.availablePurchasePlans
                                }
                                handleSelectPlan={this.selectPlan}
                                setIncludedTransactions={
                                    this.setIncludedTransactions
                                }
                                handleFeeChange={this.handleFeeChange}
                            />
                        </section>
                        <section className="spc--bottom--lrg">
                            <AddonListComponent
                                gateway={software}
                                merchantGateway={merchantSoftware}
                                availablePurchasePlans={
                                    this.availablePurchasePlans
                                }
                                selectSubequipment={this.selectSubequipment}
                                handleSubOptionSelect={this.subOptionSelect}
                                handleSubOptionChange={this.subOptionChange}
                                handleSubOptionMoreInfo={
                                    this.suboptionSetMoreInfo
                                }
                                handleSubFeeChange={this.handleSubFeeChange}
                                handleSubNote={this.handleSubNote}
                                openCloseModal={this.props.openCloseModal}
                                handleGatewayOptionSelect={this.optionSelect}
                                handleGatewayChange={this.handleChange}
                                handleOptionMoreInfo={this.optionSetMoreInfo}
                                handleFeeChange={this.handleFeeChange}
                                classNames={{}}
                                hideEquipmentOptions={true}
                            />
                        </section>
                        <section className="">
                            <h3 className="spc--bottom--med">Notes</h3>
                            {this.renderNotes(software, merchantSoftware)}
                        </section>
                    </Fragment>
                )}

                {/*
                            {/* Subequipment /}
                            {this.softwareSubequipment && this.softwareSubequipment.length > 0 &&
                                (
                                <Fragment>
                                    <div className="card--tertiary__header">
                                        {gateway.name} Sub-Equipment
                                    </div>
                                    <div className="row spc--bottom--med">
                                        {
                                            this.softwareSubequipment.map((eqp, idx) => {
                                                let merchantSub = merchantSoftware.subequipment.find((s, i) => s.equipmentId === eqp.equipmentId);
                                                return (
                                                    <div className="col col-sml-12 col-lrg-4" key={idx}>
                                                        <input className="input--check" type="checkbox" name={gateway.name + "_" + eqp.equipmentId + "_opt"}
                                                            id={gateway.name + "_" + eqp.equipmentId + "_opt"}
                                                            value={eqp.equipmentId} checked={!!merchantSub}
                                                            onChange={this.selectSubequipment} />
                                                        <label className="label" htmlFor={gateway.name + "_" + eqp.equipmentId + "_opt"}>{eqp.name} </label>
                                                    </div>
                                                );

                                            })
                                        }
                                    </div>
                                    <div className="spc--bottom--med">
                                        {
                                                this.softwareSubequipment.filter((eqp, i) => !!merchantSoftware.subequipment.find((s, i) => s.equipmentId === eqp.equipmentId))
                                                    .map((eqp, idx) => {
                                                return (
                                                    <div className="spc--bottom--med">
                                                        <label className="label spc--bottom--tny">{eqp.name} Cost Details</label>
                                                        <PurchasePlanComponent key={idx} plan={eqp.purchasePlans[0]}
                                                            merchantFees={merchantSoftware.subequipment.find((s, i) => s.equipmentId === eqp.equipmentId).fees}
                                                            purchaseType={merchantSoftware.purchaseType}
                                                            onFeeChange={this.handleSubFeeChange(eqp.equipmentId)} />
                                                    </div>)
                                            })
                                        }
                                    </div>
                                </Fragment>
                                )
                            }
                            {/*Plugins/}
                            {this.pluginSubequipment && this.pluginSubequipment.length > 0 &&
                                (
                                <Fragment>
                                    <div className="card--tertiary__header">
                                        Plugins
                                    </div>
                                    <div className="row">
                                    {
                                        this.pluginSubequipment.map((eqp, idx) => {
                                            let merchantSub = merchantSoftware.subequipment.find((s, i) => s.equipmentId === eqp.equipmentId);

                                            return (
                                                <div className="col col-sml-12 col-lrg-4" key={idx}>
                                                    <input className="input--check" type="checkbox" name={gateway.name + "_" + eqp.equipmentId + "_opt"}
                                                        id={gateway.name + "_" + eqp.equipmentId + "_opt"}
                                                        value={eqp.equipmentId} checked={!!merchantSub}
                                                        onChange={this.selectSubequipment} />
                                                    <label className="label" htmlFor={gateway.name + "_" + eqp.equipmentId + "_opt"}>{eqp.name} </label>
                                                </div>
                                            );
                                        })
                                    }
                                    </div>

                                    {
                                        this.pluginSubequipment.filter((eqp, i) => !!merchantSoftware.subequipment.find((s, i) => s.equipmentId === eqp.equipmentId))
                                            .map((eqp, idx) => {
                                            return (
                                                <div className="spc--top--med">
                                                    <label className="label spc--bottom--tny">{eqp.name} Cost Details</label>
                                                    <PurchasePlanComponent key={idx} plan={eqp.purchasePlans[0]}
                                                    merchantFees={merchantSoftware.subequipment.find((s, i) => s.equipmentId === eqp.equipmentId).fees} 
                                                    purchaseType={merchantSoftware.purchaseType} onFeeChange={this.handleSubFeeChange(eqp.equipmentId)} />
                                                </div>
                                            )
                                        })
                                    }
                                </Fragment>
                                )
                            }
                        </div>
                    </Fragment>
                )}

            </Fragment>*/}
            </Fragment>
        );
    }
}

SoftwareListItemComponent.propTypes = {
    software: object.isRequired,
    isExpanded: bool.isRequired,
    merchantSoftware: object,
    onChange: func.isRequired,
    createNewEquipment: func.isRequired,
    openCloseModal: func.isRequired,
    softwareSelect: func.isRequired,
};

export default SoftwareListItemComponent;
