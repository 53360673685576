import React, { Component, createRef, Fragment } from 'react';
import { object, func, bool, string } from 'prop-types';
import { each, cloneDeep, isEmpty, isEqual, map, find, toLower, filter, some, get, findIndex, noop, first, findLastIndex, negate, toString, includes, toNumber } from 'lodash';
import { NavbarComponent } from "./../EApp/nav-bar";
import { EquipmentComponent } from './../../components/Equipment';
import { appService } from '../../services/appService';
import { withContext, withLoader } from "./../../common/components";
import { hardwareTemplate, gatewayTemplate, subequipmentTemplate, softwareTemplate, Schema } from "../../validation";
import { withRouter } from 'react-router-dom';
import { Notification } from '../../common/components/notifications';
import { varTemplate } from '../../validation/equipment.validation';
import MerchantEquipmentFooter from '../MerchantEquipmentFooter/MerchantEquipmentFooter';
import { collapseSidebar, createNewEquipment, handleEquipmentChange } from '../../common/components/grid/commonGridMethods';
import { modalNames, ModalWrapper } from '../../common/components/modal-wrapper';
import { MerchantContext } from '../MerchantDetails';
import { categorizeEquipment, openFile} from '../../common/utilities';
import { ApplyValuesFromTemplateMethods } from '../EquipmentTemplate/utilities/equipment-edit-methods';
import { equipmentDB, equipmentDatabaseKeys as keys } from '../../helpers/indexedDB';

const schemaList = {
    gateway: new Schema(gatewayTemplate, { strip: false, typecast: true }),
    software: new Schema(softwareTemplate, { strip: false, typecast: true }),
    var: new Schema(varTemplate, { strip: false, typecast: true }),
};

class MerchantEquipmentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addons: [],
            dirty: false,
            fixTab: false,
            varList: null,
            isValid: true,
            currentTab: null,
            gatewayList: null,
            errorMessage: null,
            hardwareList: null,
            softwareList: null,
            refDataReady: false,
            successMessage: null,
            merchantEquipment: null,
            templateEquipmentList: [],
            merchantPosSystemList: null,
            existingMerchantEquipment: null,
            selectedEquipmentId: null,
            appId: this.props.match.params.appid,
            appStatus: {
                isReadyToSubmit: false,
                isMpaComplete: false,
                isSetupFormComplete: false,
                isEquipmentComplete: false,
            },
            modal: {
                name: modalNames.none,
                data: null
            },
        };

        this.topRef = createRef();
        this.sidebarRef = createRef();
        this.notificationRef = createRef();
        this.footerRef = createRef();
    }

    tabWithElements = (showOnlyOne = false) => {
        const gatewayList = this.filterForAgentVisible(this.state.gatewayList);
        const hardwareList = this.filterForAgentVisible(this.state.hardwareList);
        const softwareList = this.filterForAgentVisible(this.state.softwareList);
        const varList = this.state.varList;

        const areNotEmpty = map([gatewayList, hardwareList, softwareList, varList], negate(isEmpty));
        if (showOnlyOne && findIndex(areNotEmpty) !== findLastIndex(areNotEmpty)) return null;
        const [hasGateways, hasHardwares, hasSoftwares, hasVars] = areNotEmpty;
        if (hasGateways) return 'gateways';
        if (hasHardwares) return 'hardware';
        if (hasSoftwares) return 'software';
        if (hasVars) return 'other';
        return null;
    }
    filterForAgentVisible=(equipList)=> {
        return equipList && equipList.filter((e) => e.canBeOrdered !== undefined ? e.canBeOrdered === true : e)
    }

    async componentDidMount() {
        let equipmentList = await equipmentDB.getEquipment(keys.equipmentList, this.state.appId);
        let varList = await equipmentDB.getEquipment(keys.varList, this.state.appId);
        let merchantEquipment = await equipmentDB.getEquipment(keys.merchantEquipment, this.state.appId);
        let merchantPosSystemList = await equipmentDB.getEquipment(keys.merchantPosSystemList, this.state.appId) || [];
        const sidebarExpanded = JSON.parse(sessionStorage.getItem('sidebarExpanded'));
        sessionStorage.setItem('collapseSidebar', true);
        const wasSidebarExpanded = sidebarExpanded !== null ? sidebarExpanded : true;
        const promises = [];

        if (equipmentList) {
            this.equipmentList = equipmentList;
            const { gatewayList, softwareList, hardwareList } = categorizeEquipment(equipmentList);

            if (!merchantEquipment) {
                merchantEquipment = [];
            }
            this.validateEquipment(merchantEquipment, gatewayList, hardwareList, softwareList);
            await this.setStateAsync({ wasSidebarExpanded, gatewayList, hardwareList, softwareList, merchantEquipment, merchantPosSystemList, refDataReady: true });
        }
        else {
            this.setState({ wasSidebarExpanded, merchantEquipment: [], merchantPosSystemList });
            promises.push(this.loadEquipmentFromService());
        }

        if (varList && varList.length > 0) {
            this.validateEquipment(merchantEquipment);
            this.setState({ varList });
        } else {
            this.setState({ wasSidebarExpanded });
            promises.push(this.loadVarListFromService());
        }
        await Promise.all(promises);        
        await this.setupMerchantEquipment(merchantEquipment || []);
        this.loadExistingEquipment(false);
        this.setState({ currentTab: this.tabWithElements(), fixTab: this.tabWithElements(true) })
        this.checkIfShouldOpenSelectedEquipment();
    }

    componentWillUnmount() {
        collapseSidebar(this.state.wasSidebarExpanded);
    }

    checkIfShouldOpenSelectedEquipment = () => {
        const { location: { state }, history } = this.props;

        if (!isEmpty(state) && state.openSelectedEquipment) {
            const { currentTab, selectedEquipmentId } = state;
            this.setState({ currentTab, selectedEquipmentId }, () => {
                history.replace({ state: null });
            });
        }
    };

    validateEquipment = (merchantEquipment, gatewayList, hardwareList, softwareList, varList) => {
        if (!isEmpty(merchantEquipment)) {
            let selectedEquipment = merchantEquipment.filter((e, i) => e.isSelected);
            let hardwareSchema = new Schema(hardwareTemplate, { strip: false, typecast: true });
            let subequipmentSchema = new Schema(subequipmentTemplate, { strip: false, typecast: true });

            each(selectedEquipment, (e) => {
                let item = this.findEquipment(e.equipmentId);

                if (e.parentEquipmentId) {
                    each(gatewayList, ({ subequipment }) => {
                        const sub = find(subequipment, item => item.equipmentId == e.equipmentId);
                        if (sub) {
                            item = { ...sub };
                        }
                    });
                }

                const schema = e.parentEquipmentId ? subequipmentSchema : (schemaList[toLower(e.category)] || hardwareSchema);

                e.errors = schema.validate(Object.assign({}, e,
                    {
                        settingsSource: Object.values(get(item, 'equipmentOptions', {})),
                        equipment: item,
                    }));

            });
        }
    }

    addMissingGateways = (eqpList = [], setMerchantEqp = true) => {
        let { gatewayList } = this.state;
        each(gatewayList, item => {
            let merchantGateway = find(eqpList, (e) => e.equipmentId == item.equipmentId);
            if (!merchantGateway && (item.purchasePlans.length > 0 || item.canBeOrdered)) {
                let newGateway = createNewEquipment(this.state,this.findEquipment)(item.equipmentId, false);
                eqpList.push(newGateway);
            }
        });
        if(setMerchantEqp){
            this.handleListChange(eqpList);
        }
    }

    handleApplyEquipmentTemplateOnClick = async () => {
        const { templateEquipmentList, merchantEquipment } = this.state;
        
        const equipmentInCart = [];
        const equipmentNotInCart = [];
        each(templateEquipmentList, ue => {
            const eqpName = get(find(this.equipmentList, eqp => ue.equipmentId == eqp.equipmentId), 'name');
            if(find(merchantEquipment, me => me.equipmentId == ue.equipmentId && me.isSelected)){
                equipmentInCart.push(<li key={`${eqpName}_modal_li`}>{eqpName}</li>);
            }else{
                equipmentNotInCart.push(<li key={`${eqpName}_modal_li`}>{eqpName}</li>);
            }
        });

        const onConfirm = async () => {
            try {
                const merchantEquipmentClone = cloneDeep(merchantEquipment);
                if(templateEquipmentList.length > 0){
                    this.addEquipmentFromUserTemplate(merchantEquipmentClone, templateEquipmentList)
                    this.validateEquipment(merchantEquipmentClone);
                    this.setState(
                        { merchantEquipment: merchantEquipmentClone, successMessage: "Equipment successfully added to cart." }, 
                        () => this.handleListChange(merchantEquipmentClone)
                    );
                    setTimeout(() => {
                        this.setState({ successMessage: "" });
                    }, 5000);
                }
            } catch (error) {
                this.setState(
                    { errorMessage: "An Error Occurred While Adding Template Equipment To The Cart" }, 
                );
                setTimeout(() => {
                    this.setState({ errorMessage: "" });
                }, 5000);
            }
            
        }

        this.actionInModal(onConfirm, 
            "",
            equipmentNotInCart.length === 0 ? <button onClick={this.openCloseModal} className="btn btn--primary btn--med ">OK</button> : null,
            <div className='spc--top--med'>
                {
                    equipmentNotInCart.length > 0 && <Fragment>
                        <h6 className='spc--top--med spc--bottom--tny'>The following equipment will be added to your cart:</h6>
                        <ul>{equipmentNotInCart}</ul>
                    </Fragment>
                }
                {equipmentInCart.length > 0 && <Fragment>
                    <h6 className='spc--top--med spc--bottom--tny'>The following equipment are already in your cart:</h6>
                    <ul>{equipmentInCart}</ul>
                </Fragment>
                }
            </div>
        )
    }

    setupMerchantEquipment= async (merchantEquipment) =>{
        const { useEquipmentTemplate, isGoPlus } = this.props;
        this.addMissingGateways(merchantEquipment, false);
        if(!isGoPlus && useEquipmentTemplate){
            const templateEquipmentList = await this.getAvailableUserTemplateEquipment();
            this.setState({ templateEquipmentList: templateEquipmentList });
        }
        this.validateEquipment(merchantEquipment);
        this.setState({ merchantEquipment }, () => this.handleListChange(merchantEquipment));
    }

    setStateAsync = newState => {
        return new Promise(resolve => {
			this.setState(newState, resolve);
		});
    };

    loadEquipmentFromService = async () => {
        this.props.showLoader(true);

        try {
            const equipment = await appService.getEquipmentList(this.state.appId);
            const { gatewayList, softwareList, hardwareList } = categorizeEquipment(equipment.equipmentList);

            await this.setStateAsync({ gatewayList, hardwareList, softwareList, merchantEquipment: [], refDataReady: true });
            await equipmentDB.setEquipment(keys.equipmentList, { appId: this.state.appId, equipment: equipment.equipmentList});
            this.equipmentList = equipment.equipmentList;
            this.props.showLoader(false);
        } catch(err) {
            console.log('getEquipmentList error', err);
            this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true });
            this.props.showLoader(false);
        }
    }

    fetchUserEquipmentTemplate = async () => {
        const userEquipment = await appService.GetTemplateEquipment(0);
        return userEquipment;
    }

    getAvailableUserTemplateEquipment = async () => {
        const userEquipmentTemplate = await this.fetchUserEquipmentTemplate();
        const userEquipmentList = userEquipmentTemplate.equipmentList;
        return filter(userEquipmentList, ue => some(this.equipmentList, (eqp) => eqp.canBeOrdered && eqp.equipmentId == ue.equipmentId));
    }

    addEquipmentFromUserTemplate = async (merchantEquipment, userTemplateEquipment) => {
        const getGateway = data => filter(data, ({ category }) => toLower(category) === 'gateway');
        const isGateway = userEquipment => some(getGateway(this.equipmentList), (eqp) => eqp.equipmentId == userEquipment.equipmentId);
        const getAccessories = data => filter(data, ({ category }) => toLower(category) === 'accessories');
        const isAccessory = userEquipment => some(getAccessories(this.equipmentList), (eqp) => eqp.equipmentId == userEquipment.equipmentId);

        each(userTemplateEquipment, userEquipment => {
            const equipment = find(this.equipmentList, (eqp) => eqp.canBeOrdered && eqp.equipmentId == userEquipment.equipmentId);
            if (!equipment) return;

            let merchantEquipmentToAdjust = find(merchantEquipment, (me) => me.equipmentId == userEquipment.equipmentId);
            if(merchantEquipmentToAdjust && merchantEquipmentToAdjust.isSelected){
                return; //do not adjust equipment if it is already in the cart
            }
            if(!merchantEquipmentToAdjust){
                merchantEquipmentToAdjust = createNewEquipment(this.state,this.findEquipment)(equipment.equipmentId, true, false, false);
                merchantEquipment.push(merchantEquipmentToAdjust);
            }

            if(isAccessory(userEquipment)){
                ApplyValuesFromTemplateMethods.updateAccessoryProperties(equipment, merchantEquipmentToAdjust, userEquipment);
            }else if(isGateway(userEquipment)){
                ApplyValuesFromTemplateMethods.updateGatewayProperties(equipment, merchantEquipmentToAdjust, userEquipment, createNewEquipment(this.state,this.findEquipment));
            }else{
                ApplyValuesFromTemplateMethods.updateHardwareProperties(equipment, merchantEquipmentToAdjust, userEquipment, createNewEquipment(this.state,this.findEquipment));
            }
            merchantEquipmentToAdjust.isEquipmentAddedFromAgentTemplate = true;
        })
    }

    loadVarListFromService = () => {
        this.props.showLoader(true);

        appService.getVarList(this.state.appId)
            .then(({ varEquipment: varList }) => {
                this.setState({ varList });
                equipmentDB.setEquipment(keys.varList, { appId: this.state.appId, equipment: varList }).then(() => {
                    this.props.showLoader(false);
                });

            }
            ).catch(err => {
                console.log('getVarList error', err);
                this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true });
                this.props.showLoader(false);
            });
    };

    loadExistingEquipment = (loadMerchantGateways = true) => {
        return appService.getMerchantEquipment(this.state.appId)
            .then(
                eqp => {
                    this.setState({ existingMerchantEquipment: eqp.equipmentList });
                    if(loadMerchantGateways){
                        this.addMissingGateways()
                    }
                    return eqp.equipmentList;
                }
            ).catch(err => {
                console.log('getMerchantEquipment error', err);
                this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true });
                return [];
            });
    }

    handleTabSelect = (tab) => {
        this.setState({ currentTab: tab, selectedEquipmentId: null });
    }

    handleShowEquipment = (eqpId) => {
        this.setState({ selectedEquipmentId: eqpId });
    }

    updateState = newState => {
        this.setState(newState);
    };

    handlePosSystemChange = posSystem => {
        const list = this.state.merchantPosSystemList;
        const index = findIndex(list, { name: posSystem.name });

        if (index > -1) {
            list[index] = posSystem;
        } else {
            list.push(posSystem);
        }

        equipmentDB.setEquipment(keys.merchantPosSystemList, { appId: this.state.appId, equipment: list });
        this.setState({ merchantPosSystemList: list }, () => {
            if (this.state.isItemsModalOpen && !some(this.state.merchantEquipment, 'isSelected') && !some(this.state.merchantPosSystemList, 'isSelected')) {
                const closeItemsModal = get(this.footerRef, 'current.closeItemsModal', noop);
                closeItemsModal();
            }
        });
    };

    clearMerchantPosSystemList = () => {
        this.setState({ merchantPosSystemList: [] });
    };

    closeItemsModalAndValidate = () => {
        if (this.state.isItemsModalOpen && !some(this.state.merchantEquipment, 'isSelected')) {
            const closeItemsModal = get(this.footerRef, 'current.closeItemsModal', noop);
            closeItemsModal();
        }
        this.setErrorState();
    };

    handleListChange = (lst) => {
        let merchEquipment = cloneDeep(this.state.merchantEquipment);
        if(!merchEquipment) return;
        merchEquipment = merchEquipment.filter((el) => !lst.find(e => e.equipmentId == el.equipmentId));
        merchEquipment = merchEquipment.concat(lst);
        const eqpWithoutErrors = merchEquipment.map(({ errors, ...keepAttrs }) => keepAttrs);
        equipmentDB.setEquipment(keys.merchantEquipment, { appId: this.state.appId, equipment: eqpWithoutErrors });
        this.setState({ merchantEquipment: merchEquipment }, this.closeItemsModalAndValidate);
    }

    setErrorState = () => {
        let haveErrors = this.state.merchantEquipment.filter(e => e.isSelected && e.errors && e.errors.length > 0).length > 0
        this.setState({ isValid: !haveErrors });
    }

    handleSave = (e, redirectToConfirmPage = false, notificationMessage) => {
        e.preventDefault();
        this.saveAndGoNext(redirectToConfirmPage, notificationMessage);
    }

    componentDidUpdate(_, prevState) {
        let dirty = false;
        if (!!this.state.merchantEquipment) {
            let merchEquipment = this.state.merchantEquipment.filter((e, i) => e.isSelected);
            if (merchEquipment && merchEquipment.length > 0) {
                dirty = true;
            }
        }

        if (this.state.dirty != dirty) {
            this.setState({ dirty });
        }

        if (this.state.errorMessage && this.state.isNewError) {
            setTimeout(() => {
                this.topRef.current && this.topRef.current.scrollIntoView({ behavior: 'smooth' })
            }, 200);
            this.setState({ isNewError: false });
        }

        if (!isEqual(this.state.merchantEquipment, prevState.merchantEquipment)) {
            this.validateEquipment(this.state.merchantEquipment, this.state.gatewayList, this.state.hardwareList, this.state.softwareList, this.state.varList);
            this.setErrorState();
        }
    }

    findEquipment = (equipmentId, isSubequipment, isAccessory, parentEquipmentId) => {
        const { hardwareList, gatewayList, softwareList, varList } = this.state;

        let selectedEqp = (hardwareList && hardwareList.find((h, i) => h.equipmentId == equipmentId))
            || (gatewayList && gatewayList.find((g, i) => g.equipmentId == equipmentId)) || (softwareList && softwareList.find((g, i) => g.equipmentId == equipmentId)) ||
            (varList && varList.find((g, i) => g.equipmentId == equipmentId));

        if (isSubequipment) {
            const parentItem = find(gatewayList, p => toString(p.equipmentId) === toString(parentEquipmentId) );
            const item = find(get(parentItem, 'subequipment'), (sub) => sub.equipmentId == equipmentId);
            if (item) {
                selectedEqp = { ...item, parentEquipmentId };
            }
        }

        if (isAccessory) {
            each(hardwareList, ({ accessories }) => {
                const accessory = find(accessories, e => e.equipmentId == equipmentId);
                if (accessory) {
                    selectedEqp = accessory;
                }
            });
        }

        return selectedEqp;
    }

    downloadPrefilledVarSheet = (forPrint) => {
        const { merchant } = this.props;
        const varSheetTemplateFields = {
            'businessAddress_streetAddress': "Address",
            'businessAddress_city': "City",
            'businessAddress_state': "State",
            'businessAddress_zip': "Zip",
            'dba': "Merchant DBA Name",
            'mid': "Merchant MID",
            'phoneNumber': "Phone",
            'corporateName': "Merchant Corporate Name",
        };

        let fileName = "varSheet_" + merchant.appId + ".pdf";
        appService.prefillDocumentFields(merchant, 'varSheetTemplate', varSheetTemplateFields).then((blob) => {
            this.downloadPrefilledDocument(URL.createObjectURL(blob), fileName, forPrint)
        });
    };
    scrollToErrorMessage = () => {
        const section = document.querySelector('.header__title__wrap');
        section && section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        
    downloadPrefilledGatewaySetupForm = (forPrint) => {
        const { merchant } = this.props;
        const formName = this.getGatwaySetupFormName();
         if (isEmpty(formName)) {
             this.setState({ errorMessage: "Error: No file found for Download" }, this.scrollToErrorMessage());            
            return;
        }
        let templateFields = {
            'dba': "Merchant DBA",
            'email': "MerchantEmailAddress",
        };
        if (formName.includes('Converge')) {
            templateFields =  {
                'dba': "DBA NAME", 'mid': "MID",
                'businessEmail': "EMAIL",
                'ownerName': "CONTACT NAME",
                'businessAddress_streetAddress': "ADDRESS 1",
                'businessAddress_state': "STATE",
                'businessAddress_zip': "ZIP",
                'businessAddress_city': "CITY",
                'businessAddress_country': "COUNTRY"
            } 
        }
        let fileName = formName + "_" + merchant.appId + ".pdf";
        return appService.prefillDocumentFields(merchant, formName, templateFields).then((blob) => {
            this.downloadPrefilledDocument(URL.createObjectURL(blob), fileName,forPrint)
        });
    };

    downloadPrefilledAuthorizationForm = (forPrint) => {
        const { mid, dba,  appId } = this.props.merchant;
        let formName = this.getAuthorizationFormName()
        if (!formName) return;        
        let selectedEquip = this.state.merchantEquipment.filter((e, i) => e.isSelected)[0];
        if (!selectedEquip) return;       

        let data = { mid: mid, dba: dba, };
        let fields = {'dba': "DBA", 'mid': "MID"}
        this.props.showLoader(true);

        if (formName.includes("FDCad_WirelessFeeForm")) {
            fields = {
                'dba': "DBA", 'mid': "MID", 'date': "Date", 'phone': "Phone",
                'ownerName': "ContactName",
                'corporateName': "Corporate Name",
                'email': "Email Address"
            }
            data = this.getCadFdFormData()
        }
        if (formName.includes('ElavonCad_WirelessFeeForm')) {
            fields = {
                'dba': "DBA", 'mid': "MID", 'date': "Date", 'phone': "Phone",
                'ownerName': "ContactName",
                'name' :"Name",
                'quantity': "Quantity",
                'equipName': "EquipmentName",
                'simMonthlyFee': "SIM Card Monthly Fee",
                'simSetupFee': "SIM Card Setup Fee",
                'unitPrice': 'Price Per Unit',
                'totalAmount': 'Total Amount',
                'safeTsoloFee': 'SafeT Solo MonthlyFee',
                'shippingStreet': "StreetAddress1",
                'shippingState': "State",
                'shippingZip': "Zip",
                'shippingCity': "City",
                'shippingCountry': "Country"
            }
            data = this.getCadElavonFormData(selectedEquip)
        }
        if (includes(['Vantiv_WirelessFeeForm','CardknoxGo_WirelessFeeForm'],formName)) {
            fields = {
                'dba': "DBA", 'mid': "MID", 'date': "Date", 'phone': "Phone",
                'name' :"Name",
                'email': "Email Address",
                'quantity': "Devices",
                'shippingStreet': "StreetAddress1",
                'shippingState': "State",
                'shippingZip': "Zip",
                'shippingCity': "City",
            }
            data = this.getVantivCardknoxGoFormData(selectedEquip)
        }
        let fileName = `${formName}_${appId}.pdf`;
        appService.prefillDocumentFields(data, formName, fields).then((blob) => {
            this.downloadPrefilledDocument(URL.createObjectURL(blob), fileName, forPrint)
        }).then(() => { this.props.showLoader(false) })
            .catch(() => { this.props.showLoader(false) });
    }

    getCadElavonFormData(selectedEquip) {
        const { mid, dba, ownerName } = this.props.merchant;
        let shippingAddress = selectedEquip.shippingAddress;
        let isRental = get(selectedEquip, 'purchaseType', '') == "rental"
        let simCardType = Object.keys(selectedEquip.equipmentOptions).find(item => item.includes('SimCardSetupFee'));
        let simCardMonthlyFee = selectedEquip.equipmentOptions[simCardType] 
        let simCardSetupFee = selectedEquip.equipmentOptions[simCardType];
        let fees = get(selectedEquip, 'fees', []).filter((f) => f.purchaseTypes.includes(selectedEquip.purchaseType))[0];
        return {
            mid: mid,
            dba: dba,
            phone: "514-461-0888",
            date: new Date().toDateString(),
            ownerName: ownerName,
            name: ownerName,
            quantity: toString(selectedEquip.quantity),
            equipName: selectedEquip.name,
            simMonthlyFee: simCardMonthlyFee ? Number(simCardMonthlyFee).toFixed(2):'',
            simSetupFee: simCardSetupFee ? Number(simCardSetupFee).toFixed(2):'',
            unitPrice: toString(fees.merchantPrice.toFixed(2)),
            totalAmount: !isRental && toString((fees.merchantPrice * selectedEquip.quantity).toFixed(2)),
            safeTsoloFee: (selectedEquip.name.includes('Poynt') ? '12' : ''),
            shippingStreet: shippingAddress ? shippingAddress.streetAddress:'',
            shippingState: shippingAddress ? shippingAddress.state : '',
            shippingZip: shippingAddress ? shippingAddress.zip : '',
            shippingCity: shippingAddress ? shippingAddress.city : '',
            shippingCountry: shippingAddress ? shippingAddress.country : '',
        };
    }
    getCadFdFormData() {
        const { mid, dba, corporateName, businessEmail } = this.props.merchant;
        return {
            mid: mid,
            dba: dba,
            date: new Date().toDateString(),
            corporateName: corporateName,
            email: businessEmail
        };
    }
        getVantivCardknoxGoFormData(selectedEquip) {
            const { mid, dba,  businessEmail, ownerName, phoneNumber } = this.props.merchant;
            let shippingAddress = selectedEquip.shippingAddress;
            return {
                mid: mid,
                dba: dba,
                phone: phoneNumber,
                date: new Date().toDateString(),
                name: ownerName,
                email: businessEmail,
                quantity: toString(selectedEquip.quantity),
                shippingStreet: shippingAddress ? shippingAddress.streetAddress:'',
                shippingState: shippingAddress ? shippingAddress.state : '',
                shippingZip: shippingAddress ? shippingAddress.zip : '',
                shippingCity: shippingAddress ? shippingAddress.city : '',
            };
    }

    getGatwaySetupFormName = () => {
        const selectedEquipment = find(this.state.merchantEquipment, { isSelected: true });
        if (selectedEquipment == null) return "";
        switch (selectedEquipment.name) {
            case 'Converge':
                return "Converge_SetupForm";
            case 'GGe4-Payeezy':
                return "Gge4-Payeezy_SetupForm";
            default:
                return '';
        }
    }

    getAuthorizationFormName = () => {
        const { merchant } = this.props;
        const processor = merchant && toNumber(merchant.processorId);
        switch (processor) {
            case 60:
            case 128:
                return "Omaha_FeesAndGridsForm";
            case 21:
                return "Elavon_WirelessFeeForm";
            case 84:
            case 107:
                return "ElavonCad_WirelessFeeForm";
            case 111:
                case 117:
                return "FDCad_WirelessFeeForm";
            case 126:
                return "Vantiv_WirelessFeeForm";
            case 125:
                return "CardknoxGo_WirelessFeeForm";
            default:
                return "";
        }
    }

    downloadPrefilledDocument = (url, fileName, forPrint) => {
            let msg = "";

            if (url.errorMessage) {
                msg = url.errorMessage;
            } else {
                openFile(url, fileName, forPrint, forPrint);
            }

            this.setState({ errorMessage: msg });
    };

    isAchqEquipment = eqp => some(eqp, e => toLower(e.name) === 'achq');

    sendAchqTerms = async () => {
        const { appId, dba, businessEmail, businessContactName } = this.props.merchant;
        let appInfo = { appId: appId, merchantDba: dba, merchantEmail: businessEmail, merchantName: businessContactName };
        return await appService.sendMerchantAchqTerms(appInfo);
    };

    loadAppStatus = () => {
        return get(this.sidebarRef, 'current.loadAppStatus', noop)();
    };

    setIsReadyToSubmit = appStatus => this.setState({ appStatus });

    displaySavedNotification = (onClose = noop, notificationMessage) => {
        const addNotification = get(this.notificationRef, 'current.addNotification', noop);
        addNotification({
            message: notificationMessage ? notificationMessage : 'Equipment saved successfully',
            success: true,
            onClose,
        });
    };

    mapSelectedMerchantEquipment = (eqp) => {
        eqp = eqp.filter((e, i) => e.isSelected);
        each(eqp, (e) => {
            if (e.accessories) {
                e.accessories = e.accessories.filter((a, i) => a.isSelected && parseInt(a.quantity));
                each(e.accessories, a => {
                    a.shippingOption = eqp.shippingOption;
                    a.shippingSpeed = eqp.shippingSpeed;
                    a.shippingAddress = eqp.shippingAddress;
                });
            }
            if (!!e.subequipment) {
                each(e.subequipment, s => s.paymentSchedule = e.paymentSchedule);
            }
            if (e.additionalFees) {
                e.fees = [...e.fees, ...filter(e.additionalFees, x => x.isSelected)];
                delete e.additionalFees;
            }
        });
        return eqp;
    };

    openCloseModal = modal => this.setState({ modal });

    actionInModal = (action, question, buttonsHTML = null, bodyHTML = null) => {
        this.openCloseModal({
            name: modalNames.confirmAction,
            data: {
                bodyHTML: bodyHTML,
                onConfirm: action,
                question: question,
                buttonsHTML: buttonsHTML
            }
        });
    }

    saveAndGoNext = (redirectToConfirmPage, notificationMessage) => {
        this.props.showLoader(true);
        const eqp = this.mapSelectedMerchantEquipment(filter(cloneDeep(this.state.merchantEquipment), ({ category }) => toLower(category) !== 'var'));

        map(eqp, equipment => { 
            if(includes(['Gateway', 'Software', 'Var'], equipment.category) || !includes(["BillAgent"], equipment.paymentSchedule)){
                return equipment;
            }
            map(equipment.fees, (fee) => fee.merchantPrice = fee.agentCost);
            map(equipment.accessories, (accessory) => map(accessory.fees, (fee)=> fee.merchantPrice = fee.agentCost));
            return equipment;
        })

        const merchantVar = first(map(
            this.mapSelectedMerchantEquipment(
                filter(this.state.merchantEquipment, ({ category }) => toLower(category) === 'var')),
            ({ category, equipmentOptions, notes, equipmentId, attachment, purchaseType }) => ({ category, equipmentOptions, notes, equipmentId, attachment, purchaseType })));

        this.setState({
            errorMessage: ''
        });

        if (this.state.dirty) {
            if (!isEmpty(eqp)) {
                const data = [this.state.appId, eqp];
                appService.saveMerchantEquipment(...data)
                    .then(async () => {
                        if (this.isAchqEquipment(eqp)) {
                            await this.sendAchqTerms();
                            notificationMessage = 'Email sent to merchant to accept ACH terms and conditions. Equipment saved successfully.';
                        }
                        this.postSave(redirectToConfirmPage, notificationMessage);
                    }).catch(err => { this.handleErrorOnSave(err); });
            }
            if (!isEmpty(merchantVar)) {
                const data = [this.state.appId, merchantVar];
                appService.saveMerchantVar(...data)
                    .then(() => {
                        this.postSave(redirectToConfirmPage, notificationMessage);
                    }).catch(err => { this.handleErrorOnSave(err); });
            }
        }
        else {
            this.props.showLoader(false);

            if (redirectToConfirmPage) {
                this.goToConfirm();
            }
        }
    }


    postSave = (redirectToConfirmPage, notificationMessage) => {
        this.props.showLoader(false);

        if (redirectToConfirmPage) {
            this.displaySavedNotification(this.goToConfirm, notificationMessage);
        } else {
            this.clearState(true);
            if (this.props.isEApp) this.loadAppStatus();
            this.loadExistingEquipment();
            this.setState({
                merchantEquipment: [],
                currentTab: this.tabWithElements(),
                fixTab: this.tabWithElements(true),
                selectedEquipmentId: null
            }, () => this.topRef.current && this.topRef.current.scrollIntoView());
            this.displaySavedNotification(noop, notificationMessage);
        }
    }

    handleErrorOnSave = err => {
        console.log('save error', err);
        this.props.showLoader(false);
        this.setState({
            errorMessage: 'Please verify that no equipment was saved (by checking under \'Saved Equipment\') before trying to save again.\nError: ' + err,
            isNewError: true
        });

        if (this.props.isEApp) this.loadAppStatus();
        this.loadExistingEquipment();
    }

    goToConfirm = () => {
        const { history } = this.props;
        this.clearState();
        history.push('/eapp/confirm/' + this.state.appId);
    }

    clearState(cartOnly = false) {
        if (!cartOnly) {
            equipmentDB.deleteEquipment(keys.equipmentList, this.state.appId);
        }
        equipmentDB.deleteEquipment(keys.merchantEquipment, this.state.appId);
    }

    showMainLoader = (show) => {
        this.props.showLoader(show);
    }

    renderEquipmentFooter = () => {
        const {
            merchantEquipment,
            existingMerchantEquipment,
            hardwareList,
            gatewayList,
            softwareList,
            varList,
            isValid,
            appStatus,
            dirty,
            appId,
        } = this.state;
        const { isEApp, isGoPlus, isLoading } = this.props;

        return (
            <MerchantEquipmentFooter
                ref={this.footerRef}
                appId={appId}
                merchantEquipment={merchantEquipment}
                equipmentList={this.equipmentList}
                existingMerchantEquipment={existingMerchantEquipment}
                hardwareList={hardwareList}
                gatewayList={gatewayList}
                softwareList={softwareList}
                varList={varList}
                isValid={isValid}
                appStatus={appStatus}
                isEApp={isEApp}
                isGoPlus={isGoPlus}
                displayMissingFieldsNotification={isEApp}
                dirty={dirty}
                isLoading={isLoading}
                setErrorState={this.setErrorState}
                handleEquipmentChange={handleEquipmentChange(this.findEquipment, (e, d = noop) => this.setState(e, d), this.state, this.closeItemsModalAndValidate)}
                createNewEquipment={createNewEquipment(this.state, this.findEquipment)}
                updateState={this.updateState}
                handleSave={this.handleSave}
                findEquipment={this.findEquipment}
                reloadExistingEquipment={this.loadExistingEquipment}
                showMainLoader={this.showMainLoader}
            />
        );
    };

    render() {
        const { isEApp, isGoPlus, merchant, className, useEquipmentTemplate } = this.props;
        const { gatewayList, hardwareList, softwareList, varList, merchantEquipment, merchantPosSystemList, existingMerchantEquipment,
            appId, errorMessage, successMessage, currentTab, selectedEquipmentId, fixTab, templateEquipmentList } = this.state;

        return (
            <div id="main-div" className="l--content l--content--equipment" ref={this.topRef}>
                <Notification ref={this.notificationRef} />
                {isEApp && !isGoPlus && <NavbarComponent ref={this.sidebarRef} setIsReadyToSubmit={this.setIsReadyToSubmit} appId={appId} location={this.props.location} />}
                {isGoPlus && (
                    <ul className="tabs--primary spc--bottom--lrg">
                        <li className="tabs--primary__item" onClick={() => this.props.history.push('/eapp/cardknox-go/' + this.props.match.params.appid)} >MPA</li>
                        <li className="tabs--primary__item is-active" onClick={noop}>Equipment</li>
                    </ul>
                )}
                {merchant && merchant.dba && merchant.appId && (
                    <header className="header">
                        <div className="header__title__wrap">
                            <div className="header__title">
                                <span>
                                    {merchant.dba} - {merchant.appId}
                                </span>
                            </div>
                            {!isGoPlus && useEquipmentTemplate && templateEquipmentList.length > 0 && (
                                <button className="btn btn--primary btn--med align--h--right spc--bottom--sml" onClick={this.handleApplyEquipmentTemplateOnClick} disabled={templateEquipmentList.length <= 0}>Apply Default Equipment</button>)}
                        </div>
                    </header>
                )}
                {errorMessage ? (
                    <div className="note note--warning display--ib spc--bottom--sml" style={{ whiteSpace: 'pre-wrap' }}>
                        {errorMessage}
                    </div>
                ) : null}
                {successMessage ? (
                    <div className="note note--success display--ib spc--bottom--sml">
                        {successMessage}
                    </div>
                ) : null}
                <ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
                <div className={className}>
                    <EquipmentComponent
                        gatewayList={this.filterForAgentVisible(gatewayList)}
                        hardwareList={this.filterForAgentVisible(hardwareList)}
                        softwareList={this.filterForAgentVisible(softwareList)}
                        varList={varList}
                        merchantEquipment={merchantEquipment}
                        merchantPosSystemList={merchantPosSystemList}
                        existingEquipment={existingMerchantEquipment}
                        fixTab={fixTab}
                        selectedTab={currentTab}
                        onSelectTab={this.handleTabSelect}
                        selectedEquipmentId={selectedEquipmentId}
                        onShowEquipment={this.handleShowEquipment}
                        merchant={merchant}
                        handleEquipmentChange={handleEquipmentChange(this.findEquipment, (e, d = noop) => this.setState(e, d), this.state, this.closeItemsModalAndValidate)}
                        handlePosSystemChange={this.handlePosSystemChange}
                        clearMerchantPosSystemList={this.clearMerchantPosSystemList}
                        handleListChange={this.handleListChange}
                        createNewEquipment={createNewEquipment(this.state, this.findEquipment)}
                        openCloseModal={this.openCloseModal}
                        appId={appId}
                        dba={merchant && merchant.dba}
                        downloadPrefilledVarSheet={this.downloadPrefilledVarSheet}
                        downloadPrefilledAuthorizationForm={this.downloadPrefilledAuthorizationForm}
                        downloadPrefilledGatewaySetupForm={this.downloadPrefilledGatewaySetupForm}
                        getAuthorizationFormName={this.getAuthorizationFormName}
                        isEApp={isEApp}
                    />
                </div>
                {this.renderEquipmentFooter()}
            </div>
        );
    }
}

MerchantEquipmentComponent.defaultProps = {
    isEApp: true,
    className: '',
    useEquipmentTemplate: false,
};

MerchantEquipmentComponent.propTypes = {
    match: object,
    history: object,
    showLoader: func,
    isLoading: bool,
    isEApp: bool,
    isGoPlus: bool,
    className: string,
    merchant:object

};


export default withLoader(withRouter(withContext(MerchantEquipmentComponent, MerchantContext, 'merchant')));